<template>
  <message-incoming v-bind="{...$props, ...$attrs}" v-on="$listeners">
    <div class="bubble">
      <div class="original-message" v-if="inReplyToText">
        <div class="original-message-sender">{{ inReplyToSender }}</div>
        <div
          class="original-message-text"
          v-html="linkify($sanitize(inReplyToText))"
        />
      </div>

      <div class="message">
        <i v-if="event.isRedacted()" class="deleted-text">
          <v-icon :color="this.senderIsAdminOrModerator(this.event)?'white':''" size="small">block</v-icon>
          {{ $t('message.incoming_message_deleted_text')}}
        </i>
        <span v-html="linkify($sanitize(messageText))" v-else/>
        <span class="edit-marker" v-if="event.replacingEventId() && !event.isRedacted()">
          {{ $t('message.edited') }}
        </span>
      </div>
      <!-- <div>{{ JSON.stringify(event) }}</div> -->
    </div>
  </message-incoming>
</template>

<script>
import MessageIncoming from "./MessageIncoming.vue";
import messageMixin from "./messageMixin";

export default {
  extends: MessageIncoming,
  components: { MessageIncoming },
  mixins: [messageMixin],
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>