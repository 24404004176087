<template>
  <!-- BASE CLASS FOR INCOMING MESSAGE -->
  <div :class="messageClasses">
    <div v-if="showSenderAndTime" class="senderAndTime">
      <div class="sender">{{ eventSenderDisplayName(event) }}</div>
      <div class="time">
        {{ utils.formatTime(event.event.origin_server_ts) }}
      </div>
    </div>
    <v-avatar class="avatar" ref="avatar" size="32" color="#ededed" @click.stop="otherAvatarClicked($refs.avatar.$el)">
      <img v-if="messageEventAvatar(event)" :src="messageEventAvatar(event)" onerror="this.style.display='none'" />
      <span v-else class="white--text headline">{{
        eventSenderDisplayName(event).substring(0, 1).toUpperCase()
      }}</span>
    </v-avatar>
    <!-- SLOT FOR CONTENT -->
    <slot></slot>
    <div class="op-button" ref="opbutton" v-if="!event.isRedacted() && !$matrix.currentRoomIsReadOnlyForUser">
      <v-btn id="btn-more" icon @click.stop="showContextMenu($refs.opbutton)">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </div>
    <QuickReactions :event="eventForReactions" :timelineSet="timelineSet" v-on="$listeners"/>
    <SeenBy :room="room" :event="event"/>
  </div>
</template>

<script>
import SeenBy from "./SeenBy.vue";
import messageMixin from "./messageMixin";

export default {
    mixins: [messageMixin],
    components: { SeenBy }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>