export function registerServiceWorker() {
  if("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js");
  } else {
    console.log("No Service Worker support!");
  }
}

export async function  requestNotificationPermission() {
  if("PushManager" in window) {
    return Notification?.requestPermission().then((permission) => permission);
  } else {
    console.log("No Push API Support!");
  }
}

export function windowNotificationPermission() {
  return window?.Notification?.permission ?? 'Not_supported'
}

export function notificationCount() {
  return this.$matrix.notificationCount
}
