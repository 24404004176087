<template>
    <v-responsive v-if="item.event.getContent().msgtype == 'm.video' && item.src" :class="{'thumbnail-item': true, 'preview': previewOnly}"
        @click.stop="$emit('itemclick', {item: item})">
        <video :src="item.src"  :controls="!previewOnly" class="w-100 h-100">
            {{ $t('fallbacks.video_file') }}
        </video>
    </v-responsive>
    <v-img v-else-if="item.event.getContent().msgtype == 'm.image' && item.src" :aspect-ratio="previewOnly ? (16 / 9) : undefined" :class="{'thumbnail-item': true, 'preview': previewOnly}" :src="item.src" :contain="!previewOnly" :cover="previewOnly"
        @click.stop="$emit('itemclick', {item: item})" />
    <div v-else :class="{'thumbnail-item': true, 'preview': previewOnly, 'file-item': true}" @click.stop="$emit('itemclick', {item: item})">
        <v-icon>{{ fileTypeIcon }}</v-icon>
        <b>{{ $sanitize(fileName) }}</b>
        <div>{{ fileSize }}</div>
    </div>
</template>
<script>

import util from "../../plugins/utils";

export default {
    props: {
        /**
         * Item is an object of { event: MXEvent, src: URL } 
         */
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        previewOnly: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
    },
    computed: {
        fileTypeIcon() {
            if (util.isFileTypeAPK(this.item.event)) {
                return "$vuetify.icons.ic_apk";
            } else if (util.isFileTypeIPA(this.item.event)) {
                return "$vuetify.icons.ic_ipa";
            } else if (util.isFileTypePDF(this.item.event)) {
                return "$vuetify.icons.ic_pdf";
            } else if (util.isFileTypeZip(this.item.event)) {
                return "$vuetify.icons.ic_zip";
            }
            return "description"
        },
        fileName() {
            return util.getFileName(this.item.event);
        },
        fileSize() {
            return util.getFileSizeFormatted(this.item.event);
        }
    }
}
</script>


<style lang="scss">
@import "@/assets/css/chat.scss";

.thumbnail-item {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.file-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    flex-direction: column;
    padding: 20px;
    .v-icon {
        margin-bottom: 10px;
        color: currentColor;
    }
}
</style>